import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { PageInfo } from 'api/schemas'
import type { AdministrationState } from './types'

const initialState: AdministrationState = {
  pageInfo: {},
  items: [],
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  query: '',
  selectedItems: [],
  filter: {
    type: null,
    value: null,
  },
  reviewPendings: 0,
  options: {},
}

const administrationSlice = createSlice({
  name: 'administration',
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.items = payload
    },
    setPageInfo: (state, { payload }: { payload: Partial<PageInfo> }) => {
      state.pageInfo = payload
    },
    setSelectedItems: (state, { payload }) => {
      state.selectedItems = payload
    },
    setItemsRemoveSelection: (state, { payload }) => {
      state.items = payload.map(i => ({ ...i, selected: false }))
    },
    setSortingValue: (state, { payload }) => {
      state.sorting = payload
    },
    setQueryString: (state, { payload }) => {
      state.query = payload
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
    },
    resetSorting: state => {
      state.sorting = initialState.sorting
    },
    setReviewsPendings: (state, { payload }) => {
      state.reviewPendings = payload
    },
    setOption: (
      state,
      { payload }: PayloadAction<{ option: string; value: unknown }>
    ) => {
      const { option, value } = payload
      state.options[option] = value
    },
    reset: state => {
      return { ...initialState, reviewPendings: state.reviewPendings }
    },
  },
})

export const { actions, reducer: administration } = administrationSlice
export const {
  setItems,
  setPageInfo,
  setSelectedItems,
  setSortingValue,
  setQueryString,
  resetSorting,
  setReviewsPendings,
  setOption,
  reset,
} = actions
