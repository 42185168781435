import Error404 from 'components/Common/Errors/404'
import { lazy, useMemo } from 'react'
import {
  ADMIN_IMAGES,
  ADMINISTRATION_ADD_IMAGES,
  APPELLATION_RATING_PATH,
  BUY_REVIEW,
  BUY_WINE_MY_OFFERS_PATH,
  CREATE_BRAND_PATH,
  CREATE_COMPANY,
  CREATE_PRODUCER_PATH,
  CREATE_WINE_PATH,
  DATABASE_PATH,
  DATABASE_PATH_PREFIX,
  EDIT_BRAND_PATH,
  EDIT_COMPANY,
  EDIT_PRODUCER_PATH,
  EDIT_REVIEW,
  EDIT_WINE_PATH,
  HOMEPAGE_PATH,
  MY_COMPANY_PATH,
  REVIEWS_COMPANY_REVIEWS,
  REVIEWS_DASHBOARD,
  REVIEWS_MY_REVIEWS,
  SELL_WINE_MY_OFFERS_PATH,
  VIEW_BRAND_PATH,
  VIEW_PRODUCER_PATH,
  WINE_RATING_PATH,
  ADMINISTRATION_MERCHANT_CREATE,
  ADMINISTRATION_TRADE_OFFER_CREATE,
  ADMINISTRATION_TRADE_OFFER_EDIT,
  EDIT_OFFER_PATH,
  VIEW_OFFER_PATH,
  VIEW_WINE_PATH,
  ADMINISTRATION_TRADE_OFFERS,
  ADMINISTRATION_MERCHANT_EDIT,
  ADMINISTRATION_RETAIL_OFFER_PRICE,
  VIEW_WINE_SLUG_PATH,
  BUY_WINE_WINES,
} from 'utils/consts/path'
import PersonCategories, { ManagementRoles } from 'utils/consts/userRoles'
import { useUserDataForRoutes } from 'utils/hooks/useSelectedCompany'
import { useCheckAccessToDatabase } from 'utils/routes/utils'

const Administration = lazy(() => import('components/Pages/Administration'))
const AdministrationNewRequest = lazy(
  () => import('components/Pages/Administration/RequestCreate')
)
const AdministrationMatchedProducers = lazy(
  () =>
    import('components/Pages/Administration/Content/Requests/MatchedProducers')
)
const AdministrationPotentialProducers = lazy(
  () =>
    import(
      'components/Pages/Administration/Content/Requests/PotentialProducers'
    )
)

const WineImages = lazy(
  () => import('components/Pages/Administration/Content/WineImages')
)

const Database = lazy(() => import('components/Pages/DataBase'))
const ReviewsMyReviews = lazy(
  () => import('components/Pages/Reviews/MyReviews')
)
const ReviewsCompanyReviews = lazy(
  () => import('components/Pages/Reviews/CompanyReviews')
)
const ReviewsDashboard = lazy(
  () => import('components/Pages/Reviews/Dashboard')
)
const CreateReview = lazy(() => import('components/Pages/Review/Create'))
const EditReview = lazy(() => import('components/Pages/Review/Edit'))
const BuyReview = lazy(() => import('components/Pages/BuyReview'))

const ExhibitionCreate = lazy(
  () => import('components/Pages/Exhibition/Create')
)
const ExhibitionEdit = lazy(() => import('components/Pages/Exhibition/Edit'))

const GeoIndicationCreate = lazy(
  () => import('components/Pages/GeoIndication/Create')
)
const GeoIndicationEdit = lazy(
  () => import('components/Pages/GeoIndication/Edit')
)

const AwardCreate = lazy(() => import('components/Pages/Award/Create'))
const AwardEdit = lazy(() => import('components/Pages/Award/Edit'))

const Rewards = lazy(
  () => import('components/Pages/DataBase/Content/Awards/Rewards')
)

const RewardCreate = lazy(() => import('components/Pages/Award/Reward/Create'))
const RewardEdit = lazy(() => import('components/Pages/Award/Reward/Edit'))

const ELabelEdit = lazy(() => import('components/Pages/ELabel/Edit'))

const ImageCreate = lazy(() => import('components/Pages/Image/Create'))

const Homepage = lazy(() => import('components/Pages/Homepage'))
const MyCompany = lazy(() => import('components/Pages/MyCompany'))

const BuyWineOffers = lazy(() => import('components/Pages/BuyWine/Offers'))
const BuyWineRequestCreate = lazy(
  () => import('components/Pages/BuyWine/RequestCreate')
)
const BuyWineDraftRequestEdit = lazy(
  () => import('components/Pages/BuyWine/RequestEdit')
)
const BuyWineRequestView = lazy(
  () => import('components/Pages/BuyWine/RequestView')
)
const BuyWineMyRequests = lazy(
  () => import('components/Pages/BuyWine/MyRequests')
)
const BuyWineAddMyDeal = lazy(
  () => import('components/Pages/BuyWine/AddMyDeal')
)
const BuyWineAnalysis = lazy(() => import('components/Pages/BuyWine/Analysis'))
const BuyWineWines = lazy(() => import('components/Pages/BuyWine/Wines'))
const BuyWineAppellationRating = lazy(
  () => import('components/Pages/BuyWine/Analysis/AppellationRating')
)

const SellWineMyOffers = lazy(
  () => import('components/Pages/SellWine/MyOffers/MainContainer')
)
const SellWineMyWarehouses = lazy(
  () => import('components/Pages/SellWine/MyWarehouses')
)
const SellWineAddNewOffer2 = lazy(
  () => import('components/Pages/SellWine/AddNewOffer/Create')
)
const SellWineEditOffer = lazy(
  () => import('components/Pages/SellWine/AddNewOffer/Edit')
)
const SellWineRequests = lazy(
  () => import('components/Pages/SellWine/Requests')
)
const SellWineRequestView = lazy(
  () => import('components/Pages/SellWine/RequestView')
)
const SellWineRequestWines = lazy(
  () => import('components/Pages/SellWine/RequestWines')
)
const SellWineRequestOffers = lazy(
  () => import('components/Pages/SellWine/RequestOffers')
)
const RetailEshops = lazy(() => import('components/Pages/Retail/Eshops'))
const RetailWines = lazy(() => import('components/Pages/Retail/Wines'))
const RetailBuyersWine = lazy(
  () => import('components/Pages/Retail/BuyersWine')
)
const RetailGeoIndications = lazy(
  () => import('components/Pages/Retail/GeoIndications')
)
const Offer = lazy(() => import('components/Pages/Offer'))

const CreateCompany = lazy(() => import('components/Pages/Company/Create'))
const ViewCompany = lazy(() => import('components/Pages/Company/View'))
const EditCompany = lazy(() => import('components/Pages/Company/Edit'))

const ProducerCreate = lazy(() => import('components/Pages/Producer/Create'))
const ProducerEdit = lazy(() => import('components/Pages/Producer/Edit'))
const ProducerView = lazy(() => import('components/Pages/Producer/View'))

const MyWines = lazy(() => import('components/Pages/MyWines'))

const WineRating = lazy(() => import('components/Pages/Wine/Rating'))

const User = lazy(() => import('components/Pages/User'))

const routes = {
  Home: {
    title: 'Homepage',
    path: HOMEPAGE_PATH,
    exact: true,
    component: Homepage,
  },

  BuyWineOffers: {
    title: 'Buy Wine Offers',
    exact: true,
    path: BUY_WINE_MY_OFFERS_PATH,
    component: BuyWineOffers,
  },

  BuyWineMyRequests: {
    title: 'Buy Wine Offers',
    exact: true,
    path: '/acc/buy-wine/my-requests',
    component: BuyWineMyRequests,
  },
  BuyWineAnalysis: {
    title: 'Buy Wine Analysis',
    exact: true,
    path: '/acc/buy-wine/analysis',
    component: BuyWineAnalysis,
  },
  BuyWineWines: {
    title: 'Buy Wine Wines',
    exact: true,
    path: BUY_WINE_WINES,
    component: BuyWineWines,
  },
  BuyWineAnalysisProduct: {
    title: 'Buy Wine Analysis Product',
    exact: true,
    path: APPELLATION_RATING_PATH,
    component: BuyWineAppellationRating,
  },

  BuyWineRequestView: {
    title: 'Buy Wine Offers',
    exact: true,
    path: '/acc/buy-wine/my-requests/:id',
    component: BuyWineRequestView,
  },
  BuyWineRequestCreate: {
    title: 'Create Request',
    exact: true,
    path: '/acc/buy-wine/new-request/:check(check)?',
    component: BuyWineRequestCreate,
  },
  BuyWineDraftRequestEdit: {
    title: 'Draft Request edit',
    exact: true,
    path: '/acc/buy-wine/my-requests/:id/:mode(edit)/:check(check)?',
    component: BuyWineDraftRequestEdit,
  },

  BuyWineNewDeal: {
    title: 'Add My Deal',
    exact: true,
    path: '/acc/buy-wine/new-deal',
    component: BuyWineAddMyDeal,
  },

  SellWineMyOffers: {
    title: 'Sell Wine My offers',
    exact: true,
    path: SELL_WINE_MY_OFFERS_PATH,
    component: SellWineMyOffers,
  },

  SellWineMyWarehouses: {
    title: 'Sell Wine My warehouses',
    exact: true,
    path: '/acc/sell-wine/my-warehouses',
    component: SellWineMyWarehouses,
  },

  SellWineNewOffer: {
    title: 'Add New Offer',
    exact: true,
    path: '/acc/offer/create/:tab(wine|sale-terms|check)',
    component: SellWineAddNewOffer2,
  },

  EditOffer: {
    title: 'Edit offer',
    exact: true,
    path: EDIT_OFFER_PATH,
    component: SellWineEditOffer,
  },

  Offer: {
    title: 'Offer',
    exact: true,
    path: VIEW_OFFER_PATH,
    component: Offer,
  },

  SellWineRequests: {
    title: 'Sell Wine Requests',
    exact: true,
    path: '/acc/sell-wine/requests',
    component: SellWineRequests,
  },

  SellWineRequestView: {
    title: 'Sell Wine Request',
    exact: true,
    path: '/acc/sell-wine/requests/:id',
    component: SellWineRequestView,
  },

  SellWineRequestWines: {
    title: 'Sell Wine Requests Wines',
    exact: true,
    path: '/acc/sell-wine/requests/:id/wines',
    component: SellWineRequestWines,
  },

  SellWineRequestOffers: {
    title: 'Sell Wine Requests Offers',
    exact: true,
    path: '/acc/sell-wine/requests/:id/offers',
    component: SellWineRequestOffers,
  },

  RetailEshops: {
    title: 'Retail Eshops',
    exact: true,
    path: '/acc/retail/e-shops',
    component: RetailEshops,
  },

  RetailGeoIndications: {
    title: 'Retail Geo Indications',
    exact: true,
    path: '/acc/retail/geo-indications',
    component: RetailGeoIndications,
  },

  RetailWines: {
    title: 'Retail Wines',
    exact: true,
    path: '/acc/retail/wines/:sub(all|my-range|compare)?',
    component: RetailWines,
  },

  RetailBuyersWine: {
    title: 'Retail Buyers Wine',
    exact: true,
    path: '/acc/retail/buyers-wine',
    component: RetailBuyersWine,
  },

  CompanySetting: {
    title: 'Company settings',
    exact: true,
    path: MY_COMPANY_PATH,
    component: MyCompany,
  },

  MyWines: {
    title: 'My wines',
    exact: true,
    path: '/acc/my-wines/:tab(wines)/:sub(all|shortlist)?',
    component: MyWines,
  },

  MyBrands: {
    title: 'My brands',
    exact: true,
    path: '/acc/my-wines/:tab(brands)/:sub(approved|unapproved)?',
    component: MyWines,
  },

  MyWinesELabels: {
    title: 'My wines',
    exact: true,
    path: '/acc/my-wines/:tab(e-labels)/:sub(all|published|published-by-me|draft)?',
    component: MyWines,
  },

  CreateCompany: {
    title: 'Create company',
    path: CREATE_COMPANY + '/:step',
    component: CreateCompany,
  },

  CreateCompanyForbidden: {
    title: 'Create company',
    path: CREATE_COMPANY,
    component: Error404,
  },

  ProducerCreate: {
    title: 'Add producer',
    path: CREATE_PRODUCER_PATH,
    component: ProducerCreate,
  },

  ProducerEdit: {
    title: 'Edit producer',
    path: EDIT_PRODUCER_PATH,
    component: ProducerEdit,
  },

  ProducerView: {
    title: 'View producer',
    path: VIEW_PRODUCER_PATH,
    component: ProducerView,
  },

  ViewCompany: {
    title: 'Company view',
    exact: true,
    path: '/acc/company/:id',
    component: ViewCompany,
  },
  EditCompany: {
    title: 'Edit company',
    exact: true,
    path: EDIT_COMPANY,
    component: EditCompany,
  },

  WineCreate: {
    title: 'WineCreate',
    exact: true,
    path: CREATE_WINE_PATH,
    component: lazy(() => import('components/Pages/Wine/Create')),
  },

  WineEdit: {
    title: 'WineEdit',
    exact: true,
    path: EDIT_WINE_PATH,
    component: lazy(() => import('components/Pages/Wine/Edit')),
  },

  WineView: {
    title: 'Wine view',
    exact: true,
    path: VIEW_WINE_PATH,
    component: lazy(() => import('components/Pages/Wine/View')),
  },
  WineRedirectToSlug: {
    title: 'Wine redirect to slug',
    exact: true,
    path: VIEW_WINE_SLUG_PATH,
    component: lazy(() => import('components/Pages/Wine/View/RedirectToSlug')),
  },

  User: {
    title: 'User',
    exact: false,
    path: '/acc/user/:id?/:tab(profile|settings|companies|notification)?',
    component: User,
  },

  CreateBrand: {
    title: 'Add new brand',
    exact: true,
    path: CREATE_BRAND_PATH,
    component: lazy(() => import('components/Pages/Brands/Create')),
  },

  ViewBrand: {
    title: 'View brand',
    exact: true,
    path: VIEW_BRAND_PATH,
    component: lazy(() => import('components/Pages/Brands/View')),
  },

  EditBrand: {
    title: 'Edit brand',
    exact: true,
    path: EDIT_BRAND_PATH,
    component: lazy(() => import('components/Pages/Brands/Edit')),
  },
  AdministrationVerification: {
    title: 'User',
    exact: true,
    path: '/acc/administration/:tab(companies)/:sub(all|verified|unverified|rejected|prohibited)?',
    component: Administration,
  },
  AdministrationOffers: {
    title: 'Admin offers',
    exact: true,
    path: '/acc/administration/:tab(offers)',
    component: Administration,
  },
  AdministrationRequests: {
    title: 'Admin requests',
    exact: true,
    path: '/acc/administration/:tab(requests)',
    component: Administration,
  },
  AdministrationNewRequest: {
    title: 'New request',
    exact: true,
    path: '/acc/administration/requests/new-request/:check(check)?',
    component: AdministrationNewRequest,
  },
  AdministrationImages: {
    title: 'Admin images',
    exact: true,
    path: ADMIN_IMAGES,
    component: Administration,
  },

  Administration: {
    title: 'User',
    exact: true,
    path: '/acc/administration/:tab(users)/:sub(current|archive)?',
    component: Administration,
  },
  AdministrationReviews: {
    title: 'Reviews',
    exact: true,
    path: '/acc/administration/:tab(reviews)',
    component: Administration,
  },
  AdministrationMatchedProducers: {
    title: 'Matched producers',
    exact: true,
    path: '/acc/administration/requests/matched-producers/:tab(registered|has-offers)?',
    component: AdministrationMatchedProducers,
  },
  AdministrationPotentialProducers: {
    title: 'Potential producers',
    exact: true,
    path: '/acc/administration/requests/potential-producers',
    component: AdministrationPotentialProducers,
  },
  AdministrationMerchants: {
    title: 'Merchants',
    exact: true,
    path: '/acc/administration/:tab(e-shops)/:sub(all|deleted|active)?',
    component: Administration,
  },
  AdministrationWineImages: {
    title: 'Wine Images',
    exact: true,
    path: '/acc/administration/images/wine/:id',
    component: WineImages,
  },
  AdministrationElabels: {
    title: 'E-Labels',
    exact: true,
    path: '/acc/administration/:tab(e-labels)',
    component: Administration,
  },
  AdministrationTradeOffers: {
    title: 'Trade offers',
    exact: true,
    path: '/acc/administration/:tab(retail-offers)/:sub(all|active|hidden|deleted)?',
    component: Administration,
  },
  AdministrationPriceSources: {
    title: 'Price sources',
    exact: true,
    path: '/acc/administration/:tab(price-sources)',
    component: Administration,
  },
  AdministrationTradeOfferLots: {
    title: 'Trade offer lots',
    exact: true,
    path: `${ADMINISTRATION_TRADE_OFFERS}/:id/:tab(prices)`,
    component: Administration,
  },

  Database: {
    title: 'Database',
    exact: true,
    path: DATABASE_PATH,
    component: Database,
  },

  DatabaseExhibitions: {
    title: 'Exhibitions',
    exact: true,
    path: '/acc/database/:tab(exhibitions)',
    component: Database,
  },

  DatabaseAwards: {
    title: 'Awards',
    exact: true,
    path: '/acc/database/:tab(awards)',
    component: Database,
  },
  DatabaseGI: {
    title: 'Geo Indication',
    exact: true,
    path: '/acc/database/:tab(geo-indication)',
    component: Database,
  },

  DatabaseRewards: {
    title: 'Rewards',
    exact: true,
    path: '/acc/database/awards/:awardName',
    component: Rewards,
  },

  DatabaseProducersDuplicates: {
    title: 'Database',
    exact: true,
    path: '/acc/database/:tab(producers)/:subtab(all|duplicates)',
    component: Database,
  },

  TradeNameDuplicates: {
    title: 'Database',
    exact: true,
    path: '/acc/database/:tab(producers)/:subtab(duplicatesOf)/:id',
    component: Database,
  },

  ReviewsMyReviews: {
    title: 'My reviews',
    exact: true,
    path: `${REVIEWS_MY_REVIEWS}/:sub(all|public|private|checking|drafts|for-sale|sold)?`,
    component: ReviewsMyReviews,
  },

  ReviewsCompanyReviews: {
    title: 'Company reviews',
    exact: true,
    path: REVIEWS_COMPANY_REVIEWS,
    component: ReviewsCompanyReviews,
  },

  ReviewsDashboard: {
    title: 'Reviews dashboard',
    exact: true,
    path: REVIEWS_DASHBOARD,
    component: ReviewsDashboard,
  },

  ReviewView: {
    title: 'Review view',
    exact: true,
    path: '/acc/product/:slug/review/:id',
    component: lazy(() => import('components/Pages/Review/View')),
  },

  CreateReview: {
    title: 'Create review',
    exact: true,
    path: '/acc/review/:action(create)',
    component: CreateReview,
  },
  EditReview: {
    title: 'Edit review',
    exact: true,
    path: EDIT_REVIEW,
    component: EditReview,
  },
  BuyReview: {
    title: 'Buy review',
    exact: true,
    path: BUY_REVIEW,
    component: BuyReview,
  },

  WineRating: {
    title: 'Wine rating',
    exact: true,
    path: WINE_RATING_PATH,
    component: WineRating,
  },

  ExhibitionCreate: {
    title: 'Exhibition create',
    exact: true,
    path: '/acc/exhibition/create',
    component: ExhibitionCreate,
  },

  ExhibitionEdit: {
    title: 'Exhibition edit',
    exact: true,
    path: '/acc/exhibition/:id/edit',
    component: ExhibitionEdit,
  },

  GeoIndicationCreate: {
    title: 'Geo Indication create',
    exact: true,
    path: '/acc/geo-indication/create',
    component: GeoIndicationCreate,
  },

  GeoIndicationEdit: {
    title: 'Geo Indication edit',
    exact: true,
    path: '/acc/geo-indication/:id/edit',
    component: GeoIndicationEdit,
  },

  AwardCreate: {
    title: 'Award create',
    exact: true,
    path: '/acc/award/create',
    component: AwardCreate,
  },

  AwardEdit: {
    title: 'Award edit',
    exact: true,
    path: '/acc/award/:id/edit',
    component: AwardEdit,
  },

  RewardCreate: {
    title: 'Reward create',
    exact: true,
    path: '/acc/award/:awardId/reward/create',
    component: RewardCreate,
  },

  RewardEdit: {
    title: 'Reward edit',
    exact: true,
    path: '/acc/award/:awardId/reward/:id/edit',
    component: RewardEdit,
  },

  ELabelEdit: {
    title: 'ELabel',
    exact: true,
    path: '/acc/elabel/:id/edit/:tab(general-info|ingredients|nutrition-declaration|certificates|responsible-consumption|packaging)?',
    component: ELabelEdit,
  },

  ImageCreate: {
    title: 'ImageCreate',
    exact: true,
    path: ADMINISTRATION_ADD_IMAGES,
    component: ImageCreate,
  },

  MerchantCreate: {
    title: 'MerchantCreate',
    exact: true,
    path: ADMINISTRATION_MERCHANT_CREATE,
    component: lazy(() => import('components/Pages/Merchant/Create')),
  },

  MerchantEdit: {
    title: 'MerchantEdit',
    path: ADMINISTRATION_MERCHANT_EDIT,
    component: lazy(() => import('components/Pages/Merchant/Edit')),
  },

  TradeOfferCreate: {
    title: 'TradeOfferCreate',
    exact: true,
    path: ADMINISTRATION_TRADE_OFFER_CREATE,
    component: lazy(() => import('components/Pages/RetailOffer/Create')),
  },

  TradeOfferEdit: {
    title: 'TradeOfferEdit',
    exact: true,
    path: ADMINISTRATION_TRADE_OFFER_EDIT,
    component: lazy(() => import('components/Pages/RetailOffer/Edit')),
  },

  LotCreate: {
    title: 'LotCreate',
    exact: true,
    path: ADMINISTRATION_RETAIL_OFFER_PRICE,
    component: lazy(() => import('components/Pages/RetailOffer/Lot/Create')),
  },

  AdministrationRequestView: {
    title: 'AdministrationRequestView',
    exact: true,
    path: '/acc/administration/request/:id',
    component: lazy(() => import('components/Pages/BuyWine/RequestView')),
  },
}

const CommonRoutes = [
  routes.Home,

  routes.ViewCompany,
  routes.EditCompany,

  routes.ProducerCreate,
  routes.ProducerEdit,
  routes.ProducerView,

  routes.WineCreate,
  routes.WineEdit,
  routes.WineView,
  routes.WineRedirectToSlug,
  routes.WineRating,

  routes.User,

  routes.CreateBrand,
  routes.EditBrand,
  routes.ViewBrand,
  routes.BuyWineRequestCreate,
  routes.BuyWineDraftRequestEdit,
  routes.CreateReview,
  routes.EditReview,
  routes.Offer,
  routes.ReviewView,
]

const SellWineRoutes = [
  routes.SellWineMyOffers,
  routes.SellWineMyWarehouses,
  routes.SellWineNewOffer,
  routes.EditOffer,
  routes.SellWineRequests,
  routes.SellWineRequestView,
  routes.SellWineRequestWines,
  routes.SellWineRequestOffers,
]

const BuyWineRoutes = [
  routes.BuyWineOffers,
  routes.BuyWineMyRequests,
  routes.BuyWineRequestCreate,
  routes.BuyWineNewDeal,
  routes.BuyWineRequestView,
  routes.BuyWineAnalysis,
  routes.BuyWineWines,
  routes.BuyWineAnalysisProduct,
]
const RetailRoutes = [
  routes.RetailEshops,
  routes.RetailGeoIndications,
  routes.RetailWines,
]
const AdministrationRetailRoutes = [
  routes.RetailEshops,
  routes.RetailGeoIndications,
  routes.RetailWines,
  routes.RetailBuyersWine,
]

const MANAGEMENT_ROUTES = {
  [ManagementRoles.SUPERADMIN]: [
    routes.CreateCompanyForbidden,
    routes.AdministrationRequestView,
    ...CommonRoutes,
    routes.Administration,
    routes.AdministrationVerification,
    routes.AdministrationRequests,
    routes.AdministrationOffers,
    routes.AdministrationReviews,
    routes.AdministrationMatchedProducers,
    routes.AdministrationPotentialProducers,
    routes.AdministrationMerchants,
    routes.AdministrationImages,
    routes.AdministrationWineImages,
    routes.AdministrationElabels,
    routes.AdministrationNewRequest,
    routes.AdministrationTradeOffers,
    routes.AdministrationPriceSources,
    routes.AdministrationTradeOfferLots,
    routes.User,
    routes.Database,
    routes.DatabaseProducersDuplicates,
    routes.TradeNameDuplicates,

    routes.DatabaseExhibitions,
    routes.ExhibitionCreate,
    routes.ExhibitionEdit,
    routes.GeoIndicationCreate,
    routes.GeoIndicationEdit,
    routes.DatabaseAwards,
    routes.DatabaseGI,
    routes.AwardCreate,
    routes.AwardEdit,
    routes.DatabaseRewards,
    routes.RewardCreate,
    routes.RewardEdit,
    routes.ImageCreate,
    routes.MerchantCreate,
    routes.MerchantEdit,
    routes.TradeOfferCreate,
    routes.TradeOfferEdit,
    routes.LotCreate,
    routes.EditOffer,
    routes.SellWineNewOffer,
    ...AdministrationRetailRoutes,
  ],
  [ManagementRoles.ADMIN]: [
    routes.CreateCompanyForbidden,
    routes.Administration,
    routes.AdministrationMatchedProducers,
    routes.AdministrationPotentialProducers,
    routes.User,
    routes.DatabaseExhibitions,
    routes.ExhibitionCreate,
    routes.ExhibitionEdit,
  ],
  [ManagementRoles.MANAGER]: [
    routes.CreateCompanyForbidden,
    routes.AdministrationRequestView,
    ...CommonRoutes,
    routes.Database,
    routes.AdministrationVerification,
    routes.AdministrationTradeOffers,
    routes.AdministrationTradeOfferLots,
    routes.TradeOfferCreate,
    routes.TradeOfferEdit,
    routes.LotCreate,
    routes.MerchantCreate,
    routes.MerchantEdit,
    routes.AdministrationMerchants,
    routes.AdministrationReviews,
    routes.AdministrationOffers,
    routes.AdministrationRequests,
    routes.AdministrationNewRequest,
    routes.AdministrationMatchedProducers,
    routes.AdministrationPotentialProducers,
    routes.AdministrationImages,
    routes.AdministrationWineImages,
    routes.AdministrationElabels,
    routes.ImageCreate,
    routes.EditOffer,
    routes.SellWineNewOffer,
    ...AdministrationRetailRoutes,
  ],
  [ManagementRoles.CONTENT]: [
    routes.CreateCompanyForbidden,
    routes.AdministrationRequestView,
    ...CommonRoutes,
    routes.User,
    routes.Administration,
    routes.AdministrationVerification,
    routes.AdministrationReviews,
    routes.AdministrationOffers,
    routes.AdministrationRequests,
    routes.AdministrationMatchedProducers,
    routes.AdministrationPotentialProducers,
    routes.AdministrationMerchants,
    routes.AdministrationImages,
    routes.AdministrationWineImages,
    routes.AdministrationElabels,
    routes.AdministrationTradeOffers,
    routes.AdministrationPriceSources,
    routes.AdministrationTradeOfferLots,
    routes.Database,
    routes.DatabaseProducersDuplicates,
    routes.TradeNameDuplicates,

    routes.DatabaseExhibitions,
    routes.ExhibitionCreate,
    routes.ExhibitionEdit,
    routes.GeoIndicationCreate,
    routes.GeoIndicationEdit,
    routes.DatabaseAwards,
    routes.DatabaseGI,
    routes.AwardCreate,
    routes.AwardEdit,
    routes.DatabaseRewards,
    routes.RewardCreate,
    routes.RewardEdit,
    routes.ImageCreate,
    routes.MerchantCreate,
    routes.MerchantEdit,
    routes.TradeOfferCreate,
    routes.TradeOfferEdit,
    routes.LotCreate,
    ...AdministrationRetailRoutes,
  ],
}

export function usePrivateRoutes() {
  const { role, category: personCategory, isVerified } = useUserDataForRoutes()
  const hasAccessToDatabase = useCheckAccessToDatabase()

  return useMemo(() => {
    let items: any[] = []

    if (ManagementRoles[role]) {
      items = MANAGEMENT_ROUTES[role]
    } else if (personCategory === PersonCategories.PERSON) {
      items = [
        ...CommonRoutes,
        routes.ReviewsMyReviews,
        routes.ReviewsDashboard,
        routes.CreateCompany,
      ]
    } else if (personCategory === PersonCategories.PRODUCER) {
      items = isVerified
        ? [
            routes.CompanySetting,
            routes.MyWines,
            routes.MyBrands,
            routes.MyWinesELabels,
            routes.ReviewsMyReviews,
            routes.ReviewsCompanyReviews,
            routes.ReviewsDashboard,
            routes.CreateCompany,
            routes.ELabelEdit,
            routes.BuyReview,
            ...SellWineRoutes,
            ...RetailRoutes,
            ...CommonRoutes,
          ]
        : [
            routes.CompanySetting,
            routes.MyWines,
            routes.MyBrands,
            routes.MyWinesELabels,
            routes.ReviewsMyReviews,
            routes.ReviewsDashboard,
            routes.CreateCompany,
            ...CommonRoutes,
          ]
    } else if (
      personCategory === PersonCategories.WHOLESALER ||
      personCategory === PersonCategories.RETAILER
    ) {
      items = isVerified
        ? [
            routes.CompanySetting,
            routes.CreateCompany,
            routes.ReviewsCompanyReviews,
            routes.ReviewsMyReviews,
            routes.ReviewsDashboard,
            routes.BuyReview,
            ...BuyWineRoutes,
            ...SellWineRoutes,
            ...RetailRoutes,
            ...CommonRoutes,
          ]
        : [
            ...CommonRoutes,
            routes.CompanySetting,
            routes.CreateCompany,
            routes.ReviewsMyReviews,
            routes.ReviewsDashboard,
          ]
    }

    if (personCategory === PersonCategories.RESTAURANT) {
      items = isVerified
        ? [
            routes.CompanySetting,
            routes.CreateCompany,
            routes.ReviewsCompanyReviews,
            routes.ReviewsMyReviews,
            routes.ReviewsDashboard,
            routes.BuyReview,
            ...BuyWineRoutes,
            ...SellWineRoutes,
            ...RetailRoutes,
            ...CommonRoutes,
          ]
        : [
            ...CommonRoutes,
            routes.CompanySetting,
            routes.CreateCompany,
            routes.ReviewsMyReviews,
            routes.ReviewsDashboard,
          ]
    }

    return hasAccessToDatabase
      ? items.filter(Boolean)
      : items.filter(
          route => route && !route.path.startsWith(DATABASE_PATH_PREFIX)
        )
  }, [hasAccessToDatabase, role, personCategory, isVerified])
}
