import styled, { css } from 'styled-components'
import { ComponentPropsWithRef, FC } from 'react'

type CheckboxState = 'default' | 'indeterminate'

const CheckboxCustom = styled.span`
  position: absolute;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #f3f3f5;
  border-radius: 4px;
  border: 1px solid #b5b5be;
  box-sizing: border-box;

  ::after {
    position: absolute;
    content: '';
    left: 2px;
    top: 2px;
    height: 0;
    width: 0;
    border-radius: 5px;
    border: solid #f3f3f5;
    border-width: 0 3px 3px 0;
    opacity: 1;
  }

  :hover {
    border: 1px solid #3b42a5;
  }
`
const CheckboxInput = styled.input``
const Wrapper = styled.label<{ $state: CheckboxState }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  ${CheckboxInput} {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  ${CheckboxInput}:checked ~ ${CheckboxCustom} {
    background-color: #f3f3f5;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #b5b5be;

    :hover {
      border: 1px solid #3b42a5;
    }
  }
  ${CheckboxInput}:checked ~ ${CheckboxCustom}::after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid #3b42a5;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }

  ${props =>
    props.$state === 'indeterminate' &&
    css`
      && ${CheckboxInput} ~ ${CheckboxCustom}::after {
        transform: none;
        opacity: 1;
        top: 1px;
        border: solid #3b42a5;
        background-color: transparent;
        border-radius: 0;

        left: 3px;
        width: 9px;
        height: 6px;
        border-width: 0 0 2px;
      }
    `}
`
const CheckboxWrapper = styled.div<{ size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  align-self: flex-start;
`
const LabelWrapper = styled.div`
  margin-left: 8px;
  letter-spacing: 0.3px;
  color: var(--color-sw-60);
  font: var(--typography-12-20-medium);
`

export interface IProps extends ComponentPropsWithRef<'input'> {
  state?: CheckboxState
  label?: string
  size?: number
}

export const Checkbox: FC<IProps> = props => {
  const { label, size = 16, state = 'default' } = props
  return (
    <Wrapper $state={state}>
      <CheckboxWrapper size={size}>
        <CheckboxInput type='checkbox' {...props} />
        <CheckboxCustom />
      </CheckboxWrapper>
      {label && <LabelWrapper>{label}</LabelWrapper>}
    </Wrapper>
  )
}

export default Checkbox
